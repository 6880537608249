import selfie from './self.jpg';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <h1>{user.name}</h1>
        <img
        className="avatar"
        src={user.imageUrl}
        alt={'Photo of ' + user.name}
        style={{
          width: user.imageSize,
          height: user.imageSize
        }}
      />
        <p>
          <code>welcome to my coding world.</code>
        </p>
        <a class="navlinks" href="/projects.html">Projects</a>
      
      </header>
    </div>
  );
}
const user = {
  name: 'Van Nguyen',
  imageUrl: selfie,
  imageSize: 100,
};

export default App;
